import * as React from 'react'
import { Tag } from 'src/design-system'
import { ChatTeardrop } from '@phosphor-icons/react'

export type CommentCountProps = {
  count: number
  onClick?: (e?: React.MouseEvent) => void
}

export const CommentCount: React.VFC<CommentCountProps> = ({
  count,
  onClick,
}) => {
  if (!count) return null

  return (
    <div className="flex items-center gap-1" onClick={onClick}>
      <Tag variant="muted">{count}</Tag>
      <span className="sr-only">Comments</span>
      <ChatTeardrop className="h-4 w-4 text-orange-500" weight="fill" />
    </div>
  )
}
