import * as React from 'react'
import { CategorisedSkills } from './categorised-skills'
import { DisciplinesRow } from './disciplines-row'
import { UncategorisedSkills } from './uncategorised-skills'
import { useFrameworkPage } from '../../pages/framework-page/framework-page-context'
import { SkillsDrawer } from 'components/skills-drawer'
import { useDrawerSkills } from '../../utils/use-drawer-skills'
import { observer } from 'mobx-react-lite'
import { Plus } from '@phosphor-icons/react'
import { Button } from 'src/design-system'
import { Spacer } from './spacer'

export type FrameworkPageProps = { className?: string }

export const FrameworkPage: React.VFC<FrameworkPageProps> = observer(
  (_props) => {
    const { framework, vm, permissions } = useFrameworkPage()
    const source = 'org'
    const { onClickCollection } = useDrawerSkills(source)

    return (
      <>
        <div
          className="overflow-auto w-full h-full flex flex-col pb-8"
          id="framework-body"
        >
          <DisciplinesRow className="bg-background" />
          <CategorisedSkills />
          <UncategorisedSkills />
          {permissions?.allowAddSkillCategory && (
            <>
              <div className="sticky left-0 flex-shrink-0 w-[282px] py-2 pl-4">
                <Button
                  colourVariant="paper"
                  variant="outline"
                  fullWidth={true}
                  className="flex items-center"
                  onClick={() => vm.onClickAddSkillCategory?.()}
                >
                  <Plus
                    weight="bold"
                    size={12}
                    className="text-gray-300 -ml-1"
                    aria-hidden
                  />
                  Add a skill category
                </Button>
              </div>
              <Spacer width={`${vm.totalColWidth}px`} />
            </>
          )}
        </div>
        <SkillsDrawer
          frameworkId={framework?.id}
          frameworkSkills={vm.tSkills}
          source={source}
          defaultOpen={false}
          refreshProps={() => {
            const frameworkPageUpdatedEvent = new Event('frameworkpage:updated')
            document.dispatchEvent(frameworkPageUpdatedEvent)
          }}
          onClickCollection={onClickCollection}
        />
      </>
    )
  }
)
