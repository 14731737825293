import { DivPropsWithoutChildren } from 'src/design-system'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import cn from 'classnames'
import { DisciplineOverflow } from 'components/discipline-overflow'
import { PositionsRow } from './positions-row'
import { Spacer } from './spacer'
import { useFrameworkPage } from '../../pages/framework-page/framework-page-context'
import styles from './disciplines-row.module.scss'

const BaseDisciplinesRow = React.forwardRef<
  HTMLDivElement,
  DivPropsWithoutChildren
>((props, ref) => {
  const { className } = props

  const { framework, permissions } = useFrameworkPage()

  return (
    <div
      ref={ref}
      className={cn(styles.disciplinesRow, styles.collapsed, className)}
      id="framework-header"
    >
      <div className="sticky left-0 flex z-[5]">
        <Spacer
          width="284px"
          className="bg-background z-[21] sticky top-0 left-0"
        />
        <Spacer width="1rem" className="bg-transparent border-0" />
      </div>
      {framework.disciplines.map((discipline, _index) => {
        const positions = discipline.positions

        const positionsCount = positions.length

        return (
          <React.Fragment key={discipline.name}>
            <div
              className={styles.disciplinesSection}
              data-element-id="disciplines-row"
            >
              <div className={cn(styles.discipline, className)}>
                <div className="flex gap-1 items-center">
                  <h2>
                    {discipline.name}
                    {positionsCount > 0 && (
                      <>
                        {' '}
                        <span className="text-gray-200 pl-1" aria-hidden>
                          {positionsCount}
                        </span>
                        <span className="sr-only">
                          {positionsCount} Positions in this discipline
                        </span>
                      </>
                    )}
                  </h2>
                  <DisciplineOverflow
                    discipline={discipline}
                    editable={!!permissions?.allowUpsertDiscipline}
                  />
                </div>
              </div>
              <PositionsRow discipline={discipline} positions={positions} />
            </div>
            <Spacer />
          </React.Fragment>
        )
      })}
    </div>
  )
})

BaseDisciplinesRow.displayName = 'DisciplineRow'

export const DisciplinesRow = observer(BaseDisciplinesRow)
