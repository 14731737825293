import * as React from 'react'
import cn from 'classnames'
import { SkillRow } from './skill-row'
import { useFrameworkPage } from '../../pages/framework-page/framework-page-context'
import { observer } from 'mobx-react-lite'
import { Plus } from '@phosphor-icons/react'

export const UncategorisedSkills = observer(() => {
  const { framework, vm, permissions } = useFrameworkPage()
  const uncategorisedSkills = framework.uncategorisedSkills

  if (uncategorisedSkills.length === 0) return null

  const allowedAddSkill = permissions?.allowAddSkill

  const lastInCategory = (index: number) => {
    return index === uncategorisedSkills.length - 1
  }

  return (
    <>
      <div
        className={cn(
          'w-full sticky left-0 flex-shrink-0 top-0 flex py-2 pl-2'
        )}
      >
        <div className="w-[280px] flex gap-x-1 items-center justify-between px-2 sticky left-0">
          <h3 className="flex gap-x-1 items-center justify-start text-sm text-gray-600 pl-1">
            Uncategorised skills
          </h3>
          {allowedAddSkill && (
            <Plus
              className="cursor-pointer text-theme-60"
              weight="bold"
              size={16}
              onClick={() => {
                vm.onClickAddSkill?.()
              }}
            />
          )}
        </div>
      </div>
      {uncategorisedSkills.map((skillVariant, index) => (
        <SkillRow
          uncategorised
          skill={skillVariant.skill}
          skillVariant={skillVariant}
          key={`skill-${skillVariant.id}`}
          lastInCategory={lastInCategory(index)}
        />
      ))}
    </>
  )
})
