import {
  DivPropsWithoutChildren,
  getInitials,
  OverflowMenu,
  Tag,
  Tooltip,
} from 'src/design-system'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import cn from 'classnames'
import { CommentCount } from 'components/comments/comment-count'
import { Discipline } from 'store/modules/disciplines'
import { Position } from 'store/modules/positions'
import { store } from 'store/index'
import { useFrameworkPage } from '../../pages/framework-page/framework-page-context'
import * as Card from './card'
import styles from './position-card.module.scss'
import { SkillsRadarChart } from 'components/skills-radar-chart'

export type PositionClickHandlerProps = {
  discipline?: Discipline
  position: Position
}

type PositionCardProps = {
  allowAssignUserToPosition?: boolean
  allowEditPosition?: boolean
  discipline: Discipline
  maxSkillLevel: number
  position: Position
  showLabels?: boolean
  showMemberships?: boolean
  showRadarChartTooltip?: boolean
} & DivPropsWithoutChildren

export const PositionCard = observer((props: PositionCardProps) => {
  const {
    allowAssignUserToPosition,
    allowEditPosition,
    className,
    discipline,
    onClick,
    maxSkillLevel,
    position,
    showLabels,
    showRadarChartTooltip,
    ...restProps
  } = props

  const { vm } = useFrameworkPage()

  const series = React.useMemo(() => {
    const skills = vm.getPositionSkillsDataPoints(position)

    if (!position.name || skills.length === 0) return

    return {
      [position.name]: {
        className: 'fill-theme-compliment-1 stroke-theme-compliment-1',
        skills,
      },
    }
  }, [vm, position])

  const openCommentCount = store.comments.openCommentCount(position.comments)

  const onClickPosition = React.useCallback(
    (source) => {
      vm.positionClickHandler({
        position,
        discipline,
        source,
      })
    },
    [discipline, position, vm]
  )

  return (
    <Card.Root
      className={cn(styles.positionCard, className)}
      data-element-id={`position-card-${position.id}`}
      onClick={() => onClickPosition('framework_page')}
      showHover={!!onClick}
      {...restProps}
    >
      <div>
        <div className="flex flex-row items-center gap-x-2 justify-between">
          <div>
            {showLabels && (
              <>
                {position.customLabel && (
                  <Tag className={styles.positionTag}>
                    {position.customLabel}
                  </Tag>
                )}
                {!position.customLabel && (
                  <Tag className={styles.positionTag}>
                    {getInitials(discipline.name)}
                    {position.seniorityLevel}
                  </Tag>
                )}
              </>
            )}
          </div>
          <div className="flex gap-1">
            {allowEditPosition && (
              <CommentCount
                count={openCommentCount}
                onClick={(e) => {
                  e?.stopPropagation()

                  onClickPosition('framework_page_comment_count')
                }}
              />
            )}
            {allowEditPosition && (
              <PositionCardOverflowMenu
                allowAssignUserToPosition={allowAssignUserToPosition}
                discipline={discipline}
                position={position}
              />
            )}
          </div>
        </div>
        <h3 className="line-clamp-2 max-">{position.name}</h3>
      </div>
      <div className="flex items-center justify-end mb-4">
        {series && (
          <Tooltip
            disabled={!showRadarChartTooltip}
            content="Compare position"
            className="cursor-pointer"
          >
            <SkillsRadarChart
              hideLevels
              maxLevel={maxSkillLevel}
              onClick={
                showRadarChartTooltip
                  ? () => vm.onClickRadarChart(position)
                  : undefined
              }
              polarGridColour="#CECACA"
              series={series}
              size={40}
            />
          </Tooltip>
        )}
      </div>
    </Card.Root>
  )
})

type PositionCardOverflowMenuProps = Pick<
  PositionCardProps,
  'position' | 'discipline' | 'allowAssignUserToPosition'
>

const PositionCardOverflowMenu = observer(
  (props: PositionCardOverflowMenuProps) => {
    const { allowAssignUserToPosition, discipline, position } = props

    const { vm } = useFrameworkPage()

    return (
      <OverflowMenu.Root
        className={styles.overflowMenu}
        contentClassName={styles.overflowMenuContent}
      >
        <OverflowMenu.Item
          onClick={() => {
            vm.onViewPosition?.({
              position,
              discipline,
              source: 'view_position',
            })
          }}
        >
          View
        </OverflowMenu.Item>
        <OverflowMenu.Item
          onClick={() => {
            vm.onDuplicatePosition?.(position)
          }}
        >
          Duplicate
        </OverflowMenu.Item>
        <OverflowMenu.Item
          onClick={() => {
            vm.onMovePosition?.(position)
          }}
        >
          Move to<span aria-hidden>..</span>
        </OverflowMenu.Item>
        {allowAssignUserToPosition && (
          <OverflowMenu.Item
            onClick={() => {
              vm.onAssignUserToPosition?.(position, 'overflow-menu')
            }}
          >
            Add someone to position
          </OverflowMenu.Item>
        )}
        <OverflowMenu.Item
          onClick={() => {
            vm.onDeletePosition?.(position)
          }}
          variant="destructive"
        >
          Delete
        </OverflowMenu.Item>
      </OverflowMenu.Root>
    )
  }
)
