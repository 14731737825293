import * as React from 'react'
import cn from 'classnames'
import { DivPropsWithoutChildren } from 'src/design-system'

export type SpacerProps = {
  width?: string
  height?: string
} & DivPropsWithoutChildren

export const Spacer: React.VFC<SpacerProps> = (props) => {
  const { width = '32px', height, className, ...restProps } = props

  return (
    <div
      aria-hidden
      style={{ width, height }}
      className={cn('bg-background w-full h-full', className)}
      {...restProps}
    />
  )
}
