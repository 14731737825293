import {
  ConfirmationDialog,
  OverflowMenu,
  useModalContext,
} from 'src/design-system'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { Discipline } from 'store/modules/disciplines'
import { errorToast } from '../../utils/error-toast'
import { MOVE_TRACK_DIALOG_ID } from 'components/move-track-dialog/utils'
import { MoveTrackDialogProps } from 'components/move-track-dialog'
import { promiseToast } from '../../utils/promise-toast'
import { store } from 'store/index'
import { successToast } from '../../utils/success-toast'

type DisciplineOverflowProps = {
  discipline: Discipline
  editable?: boolean
}

export const DisciplineOverflow = observer((props: DisciplineOverflowProps) => {
  const { discipline, editable } = props

  const { openModal } = useModalContext()

  const duplicateDiscipline = async () => {
    const result = await store.disciplines.create({ clonedFrom: discipline.id })

    if (result.success) {
      document.dispatchEvent(new Event('frameworkpage:updated'))
    }

    return result
  }

  const onDelete = async () => {
    const result = await discipline.destroy()

    if (result.success) {
      successToast('Track deleted')
    } else {
      errorToast()
    }
  }

  const onDuplicate = async () => {
    promiseToast(
      `discipline-${discipline.id}-overflow-duplicate`,
      duplicateDiscipline(),
      'Track duplicated',
      undefined,
      'Duplicating track..'
    )
  }

  const onMove = () => {
    openModal<MoveTrackDialogProps>(MOVE_TRACK_DIALOG_ID, { discipline })
  }

  if (!editable) return null

  return (
    <ConfirmationDialog.Root
      body={`This will also delete positions in the ${discipline.name} track`}
      onConfirm={onDelete}
      title="Are you sure?"
    >
      <OverflowMenu.Root className="h-4 w-4" contentClassName="min-w-[160px]">
        <OverflowMenu.Item onClick={onDuplicate}>Duplicate</OverflowMenu.Item>
        <OverflowMenu.Item onClick={onMove}>
          Move to<span aria-hidden>..</span>
        </OverflowMenu.Item>
        <ConfirmationDialog.Trigger asChild>
          <OverflowMenu.Item variant="destructive">Delete</OverflowMenu.Item>
        </ConfirmationDialog.Trigger>
      </OverflowMenu.Root>
    </ConfirmationDialog.Root>
  )
})
