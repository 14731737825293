import * as React from 'react'
import { Banner, useModalContext } from 'src/design-system'
import { Info } from '@phosphor-icons/react'
import { trackEvent } from '../../services/event-tracker'
import { useStore } from 'store/context'
import { store } from 'store/index'
import { currentUrl, removeUrlParams } from '../../utils/url-helpers'
import {
  PositionModalProps,
  POSITION_MODAL_ID,
} from 'components/position-modal/utils'
import { Position } from 'store/modules/positions'
import { observer } from 'mobx-react-lite'

export const ManagePositionSkillsBanner = observer(() => {
  const { currentUser } = useStore()
  const { openModal } = useModalContext()

  const [hide, setHide] = React.useState(false)

  const onDismiss = () => {
    trackEvent('$track_manage_position_skills_banner_dismissed')
    setHide(true)
  }
  const [position, setPosition] = React.useState<Position | null>(null)

  React.useEffect(() => {
    const positionId = currentUrl()?.searchParams?.get('position_id')

    if (!positionId) return
    const position = store.positions.byId(positionId)

    if (!position) return

    setPosition(position)

    removeUrlParams('position_id')

    const frameworkOpenSkillsDrawerEvent = new Event(
      'frameworkpage:openSkillsDrawer'
    )

    document.dispatchEvent(frameworkOpenSkillsDrawerEvent)
  }, [])

  React.useEffect(() => {
    if (!position) return

    // scroll to position card and bottom of the page where new skills will be appended
    document
      .querySelector(`[data-element-id='position-card-${position?.id}']`)
      ?.scrollIntoView()

    const scrollHeight = document.querySelector('#framework-body')?.scrollHeight

    setTimeout(() => {
      if (scrollHeight) {
        document.querySelector('#framework-body')?.scrollTo({
          top: scrollHeight,
          behavior: 'auto',
        })
      }
    }, 100)
  }, [position])

  if (!currentUser || !position || hide) return null

  const openPositionModal = () => {
    openModal<PositionModalProps>(POSITION_MODAL_ID, {
      positionId: position.id,
      source: 'manage-position-skills-banner',
    })
  }

  return (
    <div className="absolute bottom-8 left-4 z-10 max-w-2xl">
      <Banner variant="warning" onDismiss={onDismiss} className="rounded">
        <div className="flex flex-row items-center gap-x-2">
          <Info weight="bold" className="w-4 h-4 flex-shrink-0" aria-hidden />
          <p className="text-yellow-800 pr-4">
            You can manage positions from this page. Simply add skills to a
            position from our library, or create your own.{' '}
            <button className={'underline'} onClick={openPositionModal}>
              Go back to {position.name}
            </button>
          </p>
        </div>
      </Banner>
    </div>
  )
})
