import { DivPropsWithoutChildren } from 'src/design-system'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import cn from 'classnames'
import { Discipline } from 'store/modules/disciplines'
import { FrameworksSkill } from 'store/modules/frameworks-skills'
import { Position } from 'store/modules/positions'
import { PositionCard } from './position-card'
import { useFrameworkPage } from '../../pages/framework-page/framework-page-context'
import * as Card from './card'
import styles from './positions-row.module.scss'

type PositionsRowProps = {
  discipline: Discipline
  positions: Position[]
} & DivPropsWithoutChildren

export const PositionsRow = observer((props: PositionsRowProps) => {
  const { framework, vm, permissions } = useFrameworkPage()

  const { discipline, positions, className, ...restProps } = props

  const showMemberships = React.useMemo(() => vm.showMemberships, [vm])

  const sortedPositions = React.useMemo(
    () => vm.getSortedPositionsByDiscipline(positions),
    [positions, vm]
  )

  const maxSkillLevel = React.useMemo(
    () =>
      vm.getMaxSkillLevel(
        framework.frameworksSkills.flatMap(
          (fs: FrameworksSkill | null) => fs?.skillVariant ?? []
        )
      ),
    [vm, framework.frameworksSkills]
  )

  if (sortedPositions.length === 0) {
    return (
      <Card.Root
        showHover={permissions.allowCreatePosition}
        onClick={() => {
          if (!permissions.allowCreatePosition) return

          vm.onAddPositionForEmptyDiscipline()
        }}
        className={cn(styles.emptyPositionHeader, className)}
        {...restProps}
      >
        <p>No Positions</p>
        {permissions?.allowCreatePosition && (
          <p className={styles.addPositionLink}>Add a position</p>
        )}
      </Card.Root>
    )
  }

  return (
    <div
      className={cn(styles.positionsRow, className)}
      data-element-id="positions-row"
      {...restProps}
    >
      {sortedPositions?.map((position) => (
        <div className="" key={position.id}>
          <PositionCard
            allowAssignUserToPosition={permissions.allowAssignUserToPosition}
            allowEditPosition={permissions.allowEditPosition}
            discipline={discipline as Discipline}
            maxSkillLevel={maxSkillLevel}
            onClick={() =>
              vm.positionClickHandler({
                position,
                discipline,
                source: 'position-card',
              })
            }
            position={position}
            showLabels={permissions.showLabels}
            showMemberships={showMemberships}
            showRadarChartTooltip={permissions.showRadarChartTooltip}
          />
        </div>
      ))}
    </div>
  )
})
