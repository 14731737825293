import * as React from 'react'
import cn from 'classnames'
import { DivPropsWithoutChildren } from 'src/design-system'
import { RequirementCard } from './requirement-card'
import { SkillCard } from './skill-card'
import { Spacer } from './spacer'
import { Skill } from 'store/modules/skills'
import { useFrameworkPage } from '../../pages/framework-page/framework-page-context'
import { observer } from 'mobx-react-lite'
import { SkillVariant } from 'store/modules/skill-variants'

export type SkillRowProps = {
  skill: Skill
  skillVariant: SkillVariant
  uncategorised?: boolean
  /**
   * Used for handling styles specific to the last skill in a category
   */
  lastInCategory?: boolean
  inLockedCategory?: boolean
} & DivPropsWithoutChildren

export const SkillRow = observer((props: SkillRowProps) => {
  const {
    skill,
    skillVariant,
    className,
    uncategorised = false,
    inLockedCategory = false,
    ...restProps
  } = props

  const { framework, vm } = useFrameworkPage()

  if (!vm || !framework) return null

  return (
    <div
      className={cn('grid grid-flow-col justify-start w-fit', className)}
      {...restProps}
    >
      <div className="pt-1 pb-1 pl-4 min-w-min sticky w-[270px] h-full left-0 bg-background">
        <SkillCard
          skillVariant={skillVariant}
          skill={skill}
          inLockedCategory={inLockedCategory}
          onClick={() => vm.skillClickHandler(skill, inLockedCategory)}
        />
      </div>
      {framework.disciplines.map((discipline) => {
        const positions = discipline.positions
        const sortedPositions = vm.getSortedPositionsByDiscipline(positions)

        if (sortedPositions.length === 0)
          return (
            <React.Fragment key={`discipline-${discipline.id}`}>
              {/* Width of a filled position card */}
              <Spacer width="240px" />
              {/* 31px to include border, providing a gap between the empty spacer/next column */}
              <Spacer width="32px" />
            </React.Fragment>
          )

        return (
          <div key={`discipline-${discipline.id}`} className="flex gap-2 pl-4">
            {sortedPositions.map((position, index) => {
              const requirement = position.requirements.find(
                (req) => req?.frameworksSkill?.skillVariant === skillVariant
              )

              const key = requirement
                ? `requirement-${requirement.id}`
                : `empty-requirement-${index}`

              return (
                <RequirementCard
                  framework={framework}
                  key={key}
                  position={position}
                  requirement={requirement}
                  skill={skill}
                  skillVariant={skillVariant}
                  uncategorised={uncategorised}
                />
              )
            })}

            {discipline !==
              framework.disciplines[framework.disciplines.length - 1] && (
              <Spacer width="8px" />
            )}
          </div>
        )
      })}
    </div>
  )
})
