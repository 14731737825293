import * as React from 'react'
import cn from 'classnames'
import { DivPropsWithoutChildren, useModalContext } from 'src/design-system'

import styles from './requirement-card.module.scss'
import { SkillBlobs } from './skill-blobs'
import * as Card from './card'
import { MinusCircle } from '@phosphor-icons/react'
import { Requirement } from 'store/modules/requirements'
import { Skill } from 'store/modules/skills'
import { Position } from 'store/modules/positions'
import { useFrameworkPage } from '../../pages/framework-page/framework-page-context'
import { observer } from 'mobx-react-lite'
import { REQUIREMENT_MODAL_ID } from 'components/requirement-modal/utils'
import { RequirementModalProps } from 'components/requirement-modal/requirement-modal'
import { SELECT_REQUIREMENT_MODAL_ID } from 'components/select-requirement-modal/utils'
import { SelectRequirementModalProps } from 'components/select-requirement-modal'
import { SkillVariant } from 'store/modules/skill-variants'
import { Framework } from 'store/modules/frameworks'

export type RequirementCardProps = {
  framework: Framework
  /**
   * If a requirement id isn't passed, we can use that to apply empty card logic
   */
  requirement?: Requirement | null
  skill: Skill
  skillVariant: SkillVariant
  position: Position
  uncategorised?: boolean
} & DivPropsWithoutChildren

export const RequirementCard = observer((props: RequirementCardProps) => {
  const {
    framework,
    skill,
    skillVariant,
    uncategorised,
    requirement,
    position,
    className,
    ...restProps
  } = props

  const { permissions, vm } = useFrameworkPage()

  const [temporaryLevel, setTemporaryLevel] = React.useState<number | null>(
    null
  )

  const [errorMessage, setErrorMessage] = React.useState<string | null>(null)

  const allowClickingRequirement = React.useMemo(() => {
    if (!!requirement && permissions.allowChangeRequirement) return true
    if (!requirement && permissions.allowCreateRequirement) return true
    if (requirement?.level) return true
  }, [requirement, permissions])

  const { openModal } = useModalContext()

  return (
    <div
      className={cn(styles.wrapper, 'group')}
      data-element-id="requirement-card"
    >
      <Card.Root
        className={cn(
          styles.requirementCard,
          !allowClickingRequirement && styles.cantAdd,
          allowClickingRequirement && styles.showHover,
          {
            [styles.hovering]:
              allowClickingRequirement &&
              !!temporaryLevel &&
              temporaryLevel !== requirement?.level,
            [styles.empty]: !requirement,
          },
          className
        )}
        onClick={() => {
          if (allowClickingRequirement) {
            if (requirement) {
              openModal<RequirementModalProps>(REQUIREMENT_MODAL_ID, {
                requirement,
              })
            } else {
              openModal<SelectRequirementModalProps>(
                SELECT_REQUIREMENT_MODAL_ID,
                {
                  position,
                  skill,
                  skillVariant,
                }
              )
            }
          }
        }}
        tabIndex={allowClickingRequirement ? 0 : undefined}
        showHover={allowClickingRequirement}
        aria-label={`Requirement card for the ${skill.name} skill in the ${position.name} position.`}
        {...restProps}
      >
        <Card.Header className={cn(styles.header, className)} {...restProps}>
          <div className={styles.blobWrapper}>
            <SkillBlobs
              framework={framework}
              skill={skill}
              skillVariant={skillVariant}
              position={position}
              requirement={requirement}
              temporaryLevel={temporaryLevel}
              setTemporaryLevel={setTemporaryLevel}
              setErrorMessage={setErrorMessage}
            />
            {requirement && permissions?.allowDeleteRequirement && (
              <a
                className={styles.dismissLink}
                onClick={(e) => {
                  e.stopPropagation()
                  vm.onDeleteRequirement(requirement)
                }}
              >
                <span className="sr-only">Delete requirement</span>
                <MinusCircle
                  aria-hidden
                  className={cn(
                    styles.dismiss,
                    'opacity-0',
                    'group-hover:opacity-100',
                    'focus-within:opacity-100'
                  )}
                />
              </a>
            )}
          </div>
        </Card.Header>
        <RequirementCardBody
          skillVariant={skillVariant}
          position={position}
          requirement={requirement}
          errorMessage={errorMessage}
          temporaryLevel={temporaryLevel}
          uncategorised={uncategorised}
        />
      </Card.Root>
    </div>
  )
})

type RequirementCardBodyProps = {
  uncategorised?: boolean
  skillVariant: SkillVariant
  position: Position
  level?: number
  requirement?: Requirement | null
  temporaryLevel: number | null
  errorMessage: string | null
}

const RequirementCardBody = observer((props: RequirementCardBodyProps) => {
  const { skillVariant, requirement, errorMessage, temporaryLevel } = props

  const { permissions, vm } = useFrameworkPage()

  const content = () => {
    if (errorMessage) {
      return <p className={styles.description}>{errorMessage}</p>
    }

    if (!requirement && !temporaryLevel && permissions.allowCreateRequirement) {
      return (
        <p className={styles.addRequirement}>
          Add requirement{' '}
          <span className={styles.addRequirementIcon} aria-hidden>
            +{' '}
          </span>
        </p>
      )
    }

    if (temporaryLevel || (requirement && requirement.level)) {
      const currentLevel = temporaryLevel || requirement?.level || 0
      let description =
        vm.getSkillLevelDescription(skillVariant, currentLevel) || ''
      const hasEmptyDescription = !description

      if (hasEmptyDescription) description = 'Empty description'

      return (
        <div
          className={cn(styles.description, {
            [styles.emptyDescription]: hasEmptyDescription,
          })}
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
      )
    }
  }

  return <Card.Body>{content()}</Card.Body>
})
