import { SKILLS_DRAWER_ID, TSkill, TSkillLevel } from 'src/design-system'
import { Category } from 'store/modules/categories'
import { Discipline } from 'store/modules/disciplines'
import { errorToast } from '../../utils/error-toast'
import { Framework } from 'store/modules/frameworks'
import { FrameworkService } from '../../services/framework-service'
import { FrameworksSkill } from 'store/modules/frameworks-skills'
import { MOVE_POSITION_DIALOG_ID } from 'components/move-position-dialog/utils'
import { MovePositionDialogProps } from 'components/move-position-dialog'
import { OnChangeRequirementLevelProps } from './use-framework.types'
import { openModal as openRailsModal } from '../../utils/open-modal'
import { Org } from 'store/modules/orgs'
import { Position } from 'store/modules/positions'
import {
  POSITION_MODAL_ID,
  PositionModalProps,
} from 'components/position-modal/utils'
import { Requirement } from 'store/modules/requirements'
import { Skill } from 'store/modules/skills'
import { SKILL_MODAL_ID, SkillModalProps } from 'components/skill-modal/utils'
import { SkillLevel } from 'store/modules/skill-levels'
import { SkillsRadarChartDataPoint } from '../../components/skills-radar-chart/skills-radar-chart.types'
import { store } from 'store/index'
import { successToast } from '../../utils/success-toast'
import { trackEvent } from '../../services/event-tracker'
import { User } from 'store/modules/users'
import { SkillVariant } from 'store/modules/skill-variants'
import { OpenConfirmationDialogProps } from '../../design-system/contexts/confirmation-dialog-context'

// TODO: Figure out a way of not passing these into the VM
export type FrameworkPageVmContext = {
  openModal: <Props extends Record<string, unknown>>(
    id: string,
    props?: Props
  ) => void
  openDrawer: <Props extends Record<string, unknown>>(
    id: string,
    props?: Props
  ) => void
  openConfirmationDialog: (
    id: string,
    props?: OpenConfirmationDialogProps
  ) => void
}

export class FrameworkPageVm {
  constructor(
    public framework: Framework,
    private service: FrameworkService,
    private org: Org,
    private ctx: FrameworkPageVmContext
  ) {}

  get positionsCount(): number {
    return this.framework.positions.length
  }

  get showMemberships() {
    const team = this.framework.team
    if (!team) return false

    const org = team.org
    if (org.membershipsVisible) return true

    const currentUser = store.currentUser
    if (!currentUser) return false

    return currentUser.isAdminOfOrg(org.id) || team.hasEditor(currentUser.id)
  }

  get showRemoveFromTeam() {
    const team = this.framework.team
    if (!team) return false

    const org = team.org

    const currentUser = store.currentUser
    if (!currentUser) return false

    return currentUser.isAdminOfOrg(org.id) || currentUser.isEditorOfOrg(org.id)
  }

  skillName(skillId: string): string | undefined {
    return this.skills?.find((s) => s.id === skillId)?.name
  }

  skillHasRequirement(skillId: string): boolean {
    return this.framework.requirements?.some((r) => r.skill?.id === skillId)
  }

  get skills(): Skill[] {
    return this.framework.frameworksSkills.flatMap(
      (fs: FrameworksSkill | null) => fs?.skill ?? []
    )
  }

  // Calculates the width of all columns in the framework minus the sticky skills column. This allows other columns to be sticky within the layout.
  get totalColWidth() {
    let totalColWidth = 8
    this.framework.disciplines.forEach((discipline, index) => {
      const positionsLength = discipline.positions.length
      totalColWidth += 240 * Math.max(positionsLength, 1) + 32
      if (index !== this.framework.disciplines.length - 1) {
        totalColWidth += 8
      }
    })
    return totalColWidth
  }

  // Deprecated - TSkill is still used in skills drawer which should use the store
  get tSkills(): TSkill[] {
    return this.skills.flatMap((s): TSkill | [] => {
      const frameworkSkill = this.framework.frameworksSkills.find(
        (fs: FrameworksSkill | null) => fs?.skill?.id === s.id
      )
      const skillLevels = frameworkSkill.skillVariant?.skillLevels?.map(
        (sl: SkillLevel): TSkillLevel => ({
          ...sl,
          createdAt: sl.createdAt.toISOString(),
          id: parseInt(sl.id, 10),
          name: sl.name || '',
          updatedAt: sl.updatedAt.toISOString(),
        })
      )

      if (!frameworkSkill) return []

      const skillVariants = store.skillVariants
        .sortedForSkill(s.id)
        ?.map((sv) => ({
          ...sv,
          id: parseInt(sv.id, 10),
          createdAt: sv.createdAt.toISOString(),
          skillId: parseInt(sv.skill.id, 10),
          inCurrentFramework:
            frameworkSkill && frameworkSkill.skillVariant?.id === sv.id,
          categoryId: frameworkSkill.category
            ? parseInt(frameworkSkill.category, 10)
            : null,
          sortIndex: frameworkSkill.listPosition,
        }))

      return {
        ...s,
        id: parseInt(s.id, 10),
        createdAt: s.createdAt.toISOString(),
        categoryId: frameworkSkill.category
          ? parseInt(frameworkSkill.category.id, 10)
          : null,
        sortIndex: frameworkSkill.listPosition,
        skillLevels,
        skillVariants,
        cloneable: !!s.cloneable,
      }
    })
  }

  get uncategorisedSkills(): SkillVariant[] {
    return this.framework.uncategorisedSkills
  }

  onClickRadarChart(position: Position) {
    window.location.href = `/compare?pos_1=${position?.slug}&source=framework-page`
  }
  onClickTeamMember(user: User | undefined) {
    window.location.href = `/users/${user?.slug}`
  }

  onViewPosition({
    position,
    discipline,
    source,
  }: {
    position: Position
    discipline?: Discipline
    source: string
  }) {
    this.onClickPosition(position, discipline, source)
    trackEvent('$track_framework_page_view_position', {
      position_id: position.id,
    })
  }

  onDeletePosition(position: Position) {
    this.ctx.openConfirmationDialog('framework-page-dialog', {
      title: 'Are you sure you want to delete this position?',
      body: 'Deleting this position will remove all the associated content and skill level requirements.',
      confirmLabel: 'Delete position',
      onConfirm: async () => {
        if (!position.id) return
        await this.service.deletePosition({
          positionId: position.id,
        })
        trackEvent('$track_framework_page_delete_position', {
          position_id: position.id,
        })
      },
    })
  }

  async onDuplicatePosition(position: Position) {
    const result = await store.positions.create({ origin: position.id })
    if (result.success) {
      successToast('Position duplicated')
      document.dispatchEvent(new Event('frameworkpage:updated'))
    } else {
      errorToast()
    }
  }

  onAssignUserToPosition(position: Position | undefined, source?: string) {
    openRailsModal(
      `/assign_user_to_position_modal?limit=2&org_id=${this.org.id}&position_id=${position?.id}&origin=positions_page&source=${source}`
    )
  }
  onChangeRequirementLevel(clickProps: OnChangeRequirementLevelProps) {
    if (this.skillHasRequirement(clickProps.skillId)) {
      this.service.changeRequirement(clickProps)
    } else {
      this.ctx.openConfirmationDialog('framework-page-dialog', {
        title: `Automatically add ${this.skillName(
          clickProps.skillId
        )} to all ${this.positionsCount} positions`,
        body: 'Skill levels will be automatically mapped to seniority level of each poisition. For example, Seniority level 6 will get skill level 5, if there are only 5 skill levels',
        cancelLabel: "I'll do this later",
        confirmLabel: 'Add to all positions',
        onConfirm: () => {
          this.service.changeRequirement({
            ...clickProps,
            fillRequirements: true,
          })
        },
        onCancel: () => {
          this.service.changeRequirement(clickProps)
        },
      })
    }
  }

  async onDeleteRequirement(requirement: Requirement) {
    await this.service.deleteRequirement(requirement)
  }

  onClickPosition(
    position: Position,
    discipline?: Discipline,
    source?: string
  ) {
    this.ctx.openModal<PositionModalProps>(POSITION_MODAL_ID, {
      disciplineId: discipline?.id?.toString(),
      positionId: position.id.toString(),
      source,
    })
  }

  onMovePosition(position: Position) {
    this.ctx.openModal<MovePositionDialogProps>(MOVE_POSITION_DIALOG_ID, {
      position,
    })
  }

  positionClickHandler({
    position,
    discipline,
    source,
  }: {
    position: Position
    discipline: Discipline
    source: string
  }) {
    return this.onClickPosition(position, discipline, source)
  }

  skillClickHandler(
    skill: Skill,
    inLockedCategory: boolean,
    skillVariantId?: string,
    source?: string
  ) {
    this.ctx.openModal<SkillModalProps>(SKILL_MODAL_ID, {
      frameworkId: this.framework.id.toString(),
      orgId: this.org?.id?.toString(),
      showRemoveFromTeam: !inLockedCategory || store.currentUser?.isAdmin,
      skillId: skill.id.toString(),
      skillVariantId,
      source,
    })
  }

  onAddPositionForEmptyDiscipline() {
    if (!this.framework.team) return

    return this.service.onAddPositionForEmptyDiscipline(
      this.framework.team?.slug
    )
  }

  onClickAddSkill(category?: Category) {
    trackEvent('$track_skills_drawer_opened', {
      source: 'framework-row',
    })

    this.ctx.openDrawer(SKILLS_DRAWER_ID, { category })
  }

  onClickAddSkillCategory() {
    trackEvent('$track_add_skill_category_modal_opened', {
      source: 'framework',
    })

    openRailsModal(
      `/add_category_modal?framework_id=${this.framework.id}&redirect_path=${location.pathname}`
    )
  }

  getMaxSkillLevel(skillVariants: SkillVariant[]) {
    const skillLevels = skillVariants.flatMap<SkillLevel>(
      (skillVariant) => skillVariant.skillLevels || []
    )

    return skillLevels.reduce(
      (max, skillLevel) => Math.max(max, skillLevel.level ?? 0),
      0
    )
  }

  getPositionSkillsDataPoints(position: Position) {
    const frameworksSkills = this.framework.frameworksSkills || []
    const requirements = position.requirements || []

    return requirements.reduce<SkillsRadarChartDataPoint[]>(
      (acc, requirement) => {
        if (!requirement?.skill) {
          return acc
        }

        const frameworkSkill = frameworksSkills.find(
          (fs) => fs.skill?.id === requirement.skill?.id
        )

        if (frameworkSkill?.category && frameworkSkill?.skill) {
          acc.push({
            id: frameworkSkill.skill.id,
            category: frameworkSkill.category.name,
            level: requirement.level,
            name: frameworkSkill.skill.name,
          })
        }

        return acc
      },
      []
    )
  }

  getSkillLevelDescription(skillVariant: SkillVariant, level: number) {
    return skillVariant.skillLevels?.find((l) => l?.level === level)?.name
  }

  getSortedPositionsByDiscipline(positions: Position[]) {
    const sortedPositions = positions?.sort((a, b) => {
      return a.seniorityLevel === b.seniorityLevel
        ? a.name.localeCompare(b.name)
        : a.seniorityLevel - b.seniorityLevel
    })

    return sortedPositions || []
  }
}
