import {
  GlobalConfirmationDialog,
  SKILLS_DRAWER_ID,
  useDrawerContext,
} from 'src/design-system'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import cn from 'classnames'
import { FrameworkPage } from 'components/framework-page'
import { FrameworkPageProvider } from './framework-page-context'
import { LoadingContent } from 'components/loading-content'
import { ManagePositionSkillsBanner } from 'components/framework-page/manage-position-skills-banner'
import { Permissions } from './use-framework.types'
import { store } from 'store/index'
import { useRailsContext } from 'components/rails-context'
import { useStore } from 'store/context'

export type RailsFrameworkPageProps = {
  className?: string
  frameworkId: string
  permissions: Permissions
}

export const RailsFrameworkPage: React.VFC<RailsFrameworkPageProps> = observer(
  (initialProps) => {
    const { className, ...props } = initialProps

    const { frameworks } = useStore()

    const { org } = useRailsContext()

    const drawerContext = useDrawerContext()

    const [fwLoaded, setFwLoaded] = React.useState<boolean>(false)

    const loadFrameworkData = React.useCallback(
      async (bypassCache = false) => {
        await store.frameworks.fetchOne(
          `${props.frameworkId}`,
          {
            include: [
              'categories',
              'disciplines',
              'frameworks_skills',
              'frameworks_skills.category',
              'frameworks_skills.skill_variant',
              'frameworks_skills.skill_variant.skill',
              'frameworks_skills.skill_variant.skill_levels',
              'frameworks_skills.skill_variant.skill_levels.outcomes',
              'positions',
              'positions.disciplines',
              'positions.requirements',
              'positions.users',
            ],
          },
          { bypassCache }
        )
        store.comments.fetchUnresolvedForFramework(props.frameworkId)
      },
      [props.frameworkId]
    )

    React.useEffect(() => {
      document.addEventListener('frameworkpage:updated', () =>
        loadFrameworkData(true)
      )

      return () => {
        document.removeEventListener('frameworkpage:updated', () =>
          loadFrameworkData(true)
        )
      }
    }, [loadFrameworkData])

    React.useEffect(() => {
      const loadFw = async () => {
        await loadFrameworkData()
        setFwLoaded(true)
      }

      loadFw()
    }, [props.frameworkId, frameworks, loadFrameworkData])

    React.useEffect(() => {
      const openSkillsDrawer = async () => {
        drawerContext?.openDrawer(SKILLS_DRAWER_ID, { category: undefined })
      }

      document.addEventListener(
        'frameworkpage:openSkillsDrawer',
        openSkillsDrawer
      )
      return () => {
        document.removeEventListener(
          'frameworkpage:openSkillsDrawer',
          openSkillsDrawer
        )
      }
    }, [drawerContext])

    if (!org) return null

    return (
      <LoadingContent loading={!fwLoaded}>
        <FrameworkPageProvider
          frameworkId={props.frameworkId}
          permissions={props.permissions}
        >
          <GlobalConfirmationDialog id="framework-page-dialog" />
          <FrameworkPage
            {...props}
            className={cn(
              className,
              'h-full',
              drawerContext.activeDrawerId && 'pr-[400px]'
            )}
          />
          <ManagePositionSkillsBanner />
        </FrameworkPageProvider>
      </LoadingContent>
    )
  }
)
