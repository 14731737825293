import React from 'react'
import { FrameworkPageVm } from './framework-page-vm'
import { FrameworkService } from '../../services/framework-service'
import { useApiClient } from '../../utils/use-api-client'
import { useStore } from 'store/context'
import { Framework } from 'store/modules/frameworks'
import { Permissions } from './use-framework.types'
import {
  useConfirmationDialogContext,
  useDrawerContext,
  useModalContext,
} from 'src/design-system'
import { observer } from 'mobx-react-lite'

export type FrameworkPageContext = {
  vm: FrameworkPageVm
  framework: Framework
  permissions: Permissions
}

const FrameworkPageContext = React.createContext<FrameworkPageContext>(
  {} as FrameworkPageContext
)

export const FrameworkPageProvider = observer<{
  frameworkId: string
  children: React.ReactNode
  permissions: Permissions
}>((props) => {
  const { children, frameworkId, permissions } = props

  const { frameworks, currentUser } = useStore()
  const client = useApiClient()
  const service = React.useMemo(() => new FrameworkService(client), [client])
  const framework = frameworks.byId(frameworkId)
  const drawerContext = useDrawerContext()
  const { openConfirmationDialog } = useConfirmationDialogContext()
  const { openModal } = useModalContext()

  const vm = React.useMemo(
    () =>
      framework &&
      currentUser?.org &&
      new FrameworkPageVm(framework, service, currentUser.org, {
        openConfirmationDialog,
        openModal,
        openDrawer: drawerContext.openDrawer,
      }),
    [
      currentUser?.org,
      drawerContext.openDrawer,
      framework,
      openConfirmationDialog,
      openModal,
      service,
    ]
  )

  if (!vm || !framework) return null
  return (
    <FrameworkPageContext.Provider value={{ vm, framework, permissions }}>
      {children}
    </FrameworkPageContext.Provider>
  )
})

export const useFrameworkPage = () => React.useContext(FrameworkPageContext)
