import * as React from 'react'
import cn from 'classnames'
import { DivPropsWithoutChildren } from 'src/design-system'
import styles from './card.module.scss'

type RootProps = {
  children: React.ReactNode
  showHover?: boolean
} & DivPropsWithoutChildren

const Root: React.VFC<RootProps> = (props) => {
  const { children, showHover, className, ...restProps } = props

  return (
    <div
      className={cn(styles.card, { [styles.showHover]: showHover }, className)}
      {...restProps}
    >
      {children}
    </div>
  )
}

type HeaderProps = {
  children: React.ReactNode
} & DivPropsWithoutChildren

const Header: React.VFC<HeaderProps> = (props) => {
  const { children, className, ...restProps } = props

  return (
    <div className={cn(styles.header, className)} {...restProps}>
      {children}
    </div>
  )
}

type BodyProps = {
  children?: React.ReactNode
} & DivPropsWithoutChildren

const Body: React.VFC<BodyProps> = (props) => {
  const { children, className, ...restProps } = props
  return (
    <div className={cn(styles.body, className)} {...restProps}>
      {children}
    </div>
  )
}

export { Root, Header, Body }
export type { RootProps, HeaderProps, BodyProps }
