import * as React from 'react'
import { SkillRow } from './skill-row'
import { GlobalConfirmationDialog, sortBy, Tooltip } from 'src/design-system'
import { Category } from 'store/modules/categories'
import { useFrameworkPage } from '../../pages/framework-page/framework-page-context'
import { observer } from 'mobx-react-lite'
import { FrameworksSkill } from 'store/modules/frameworks-skills'
import { Lock, Plus } from '@phosphor-icons/react'
import { Spacer } from './spacer'

export const CategorisedSkills = observer(() => {
  const { framework, vm, permissions } = useFrameworkPage()

  const allowedAddSkill = permissions?.allowAddSkill
  const categories = framework.sortedCategories

  const sortedCategoryFrameworksSkills = (category: Category) => {
    const categoryFrameworksSkills = framework.frameworksSkills.filter(
      (fs: FrameworksSkill | null) => fs?.category?.id === category.id
    )
    return categoryFrameworksSkills
      ? sortBy(categoryFrameworksSkills, 'listPosition')
      : []
  }

  return (
    <>
      <GlobalConfirmationDialog id="skill-team-remove" />
      {categories.map((category) => (
        <React.Fragment key={category.id}>
          <div className="w-fit bg-background flex py-2">
            <div className="w-[280px] flex gap-x-1 items-center justify-between pl-2 sticky left-0">
              <h3 className="flex gap-x-1 items-center justify-start text-sm text-gray-600 pl-3">
                {category.name}
                {category.locked && permissions?.showLockedCategoryIcon && (
                  <Tooltip content="Categories which are locked can’t be edited by non-admins">
                    <Lock size={14} className="text-gray-300" weight="bold" />
                  </Tooltip>
                )}
              </h3>
              {allowedAddSkill && (
                <Plus
                  className="cursor-pointer text-theme-60"
                  weight="bold"
                  size={16}
                  onClick={() => {
                    vm.onClickAddSkill?.(category)
                  }}
                />
              )}
            </div>
            <Spacer width={`${vm.totalColWidth}px`} />
          </div>
          {sortedCategoryFrameworksSkills(category).map(
            (frameworksSkill, categorySkillIndex) => {
              const skillVariant = frameworksSkill.skillVariant
              if (!skillVariant) return null
              const skill = skillVariant.skill
              const frameworksSkills = sortedCategoryFrameworksSkills(category)

              const lastInCategory = (categorySkillIndex: number) => {
                return (
                  !allowedAddSkill &&
                  !!frameworksSkills &&
                  categorySkillIndex === frameworksSkills.length - 1
                )
              }

              return (
                <SkillRow
                  skillVariant={skillVariant}
                  skill={skill}
                  inLockedCategory={category.locked}
                  key={`skill-${skill.id}`}
                  lastInCategory={lastInCategory(categorySkillIndex)}
                />
              )
            }
          )}
        </React.Fragment>
      ))}
    </>
  )
})
