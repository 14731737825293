import { observer } from 'mobx-react-lite'
import * as React from 'react'
import cn from 'classnames'
import { CommentCount } from 'components/comments/comment-count'
import { Skill } from 'store/modules/skills'
import { SkillVariant } from 'store/modules/skill-variants'
import { store } from 'store/index'
import { useFrameworkPage } from 'pages/framework-page/framework-page-context'
import * as Card from './card'
import styles from './skill-card.module.scss'
import { useConfirmationDialogContext } from 'src/design-system'
import { MinusCircle } from '@phosphor-icons/react'
import { successToast } from '../../utils/success-toast'
import { closeModal } from '../../utils/close-modal'
import { errorToast } from '../../utils/error-toast'
import { stripHtmlTags } from 'app/packs/src/utils/string-helpers'

export type SkillCardProps = {
  inLockedCategory?: boolean
  skill: Skill
  skillVariant: SkillVariant
} & Omit<Card.RootProps, 'children'>

export const SkillCard = observer((props: SkillCardProps) => {
  const {
    className,
    inLockedCategory = false,
    onClick,
    skill,
    skillVariant,
    ...restProps
  } = props
  const { currentUser } = store

  const { vm } = useFrameworkPage()
  const { openConfirmationDialog } = useConfirmationDialogContext()

  const openCommentCount = store.comments.openCommentCount(
    skillVariant.comments
  )

  const showRemoveFromTeam = vm.showRemoveFromTeam

  const showComments =
    skill?.org &&
    (currentUser?.isAdminOfOrg(skill.org.id) ||
      currentUser?.isEditorOfOrg(skill.org.id))

  const onClickSkill = React.useCallback(
    (source) => {
      vm.skillClickHandler(skill, inLockedCategory, skillVariant.id, source)
    },
    [vm, skill, inLockedCategory, skillVariant.id]
  )

  const dispatchEvents = React.useCallback(() => {
    document.dispatchEvent(new Event('frameworkpage:updated'))
    document.dispatchEvent(new Event('refreshOrgSkills'))
    document.dispatchEvent(new Event('refreshSkillsDrawerSkills'))
    document.dispatchEvent(new Event('refreshTeamSkillsPage'))
  }, [])

  const confirmRemove = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    e.stopPropagation()

    openConfirmationDialog('skill-team-remove', {
      title: `Are you sure you want to remove this skill?`,
      body: `This will remove ${skill.name} from the team and all positions in the team. Usage in other teams will not be affected.`,
      onConfirm: async () => {
        const frameworkSkill =
          store.frameworksSkills.forFrameworkAndSkillVariant(
            vm.framework.id,
            skillVariant.id
          )

        if (!frameworkSkill) return

        const result = await frameworkSkill.destroy({
          source: 'framework-page',
        })

        if (result.success) {
          successToast('Skill removed')
          dispatchEvents()
          closeModal()
        } else {
          errorToast()
        }
      },
    })
  }

  return (
    <Card.Root
      onClick={() => onClickSkill('framework_page')}
      showHover={!!onClick}
      className={cn(styles.skillCard, 'group', className)}
      {...restProps}
    >
      <Card.Header className={styles.title}>
        <h4>{skillVariant.fullName}</h4>

        {showRemoveFromTeam && (
          <button onClick={confirmRemove}>
            <MinusCircle
              aria-hidden
              className={cn(
                styles.dismiss,
                'opacity-0',
                'group-hover:opacity-100',
                'focus-within:opacity-100'
              )}
            />
            <span className="sr-only">Remove from team</span>
          </button>
        )}
      </Card.Header>

      {skill.description && (
        <Card.Body className={styles.description}>
          {stripHtmlTags(skill.description)}
        </Card.Body>
      )}
      {showComments && (
        <div className="flex justify-end m-2">
          <CommentCount
            count={openCommentCount}
            onClick={(e) => {
              e?.stopPropagation()

              onClickSkill('framework_page_comment_count')
            }}
          />
        </div>
      )}
    </Card.Root>
  )
})
