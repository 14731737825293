import { Banner, Link, useTimedContent } from 'src/design-system'
import { observer } from 'mobx-react-lite'
import { Sparkle } from '@phosphor-icons/react'
import * as React from 'react'
import { trackEvent } from '../../services/event-tracker'

export const FrameworkPageAssistant = observer(() => {
  const variant = 'browse-library'

  const { hideFor, show } = useTimedContent(
    `feedback-request-banner-${variant}`
  )

  const onDismiss = () => {
    hideFor({ days: 7 })

    trackEvent('$track_framework_assistant_dismissed', {
      variant,
      source: 'framework-page',
    })
  }

  if (!show) return null

  return (
    <div className="w-4/5 md:w-[680px] m-auto">
      <Banner
        variant="highlight"
        className="p-3 rounded bg-opacity-100"
        onDismiss={onDismiss}
      >
        <div className="flex flex-row items-center gap-x-2 pr-2">
          <Sparkle className="w-4 h-4 flex-shrink-0" weight="bold" />
          <BrowseLibraryContent />
        </div>
      </Banner>
    </div>
  )
})

const BrowseLibraryContent = () => (
  <p className="text-pink-700">
    Framework not quite right? Browse our skill library to keep building it out.{' '}
    <Link
      className="text-pink-600 cursor-pointer"
      onClick={() => {
        trackEvent('$track_framework_assistant_clicked', {
          action: 'browse-library',
          source: 'framework-page',
        })

        const frameworkOpenSkillsDrawerEvent = new Event(
          'frameworkpage:openSkillsDrawer'
        )
        document.dispatchEvent(frameworkOpenSkillsDrawerEvent)
      }}
    >
      Browse library
    </Link>
  </p>
)
